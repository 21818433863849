import { constants } from '@wsb/guac-widget-core';
import {
  FILL,
  FIT,
  INSET,
  BLUR,
  LEGACY_BLUR,
  WIDE_INSET
} from '../../common/constants/headerTreatments';
import { COMMON_BUTTON_CONFIG } from '../../common/constants';
import imageToHeaderTreatments from '../../common/utils/imageToHeaderTreatments';
import { IMAGE, VIDEO, SLIDESHOW, NONE } from '../../common/constants/mediaTypes';

const { colorPackCategories, buttons } = constants;
const { LIGHT, LIGHT_ALT, LIGHT_COLORFUL, DARK, DARK_ALT, DARK_COLORFUL, COLORFUL, CUSTOM } =
  constants.paintJobs;

const id = 'layout28';
const imageTreatments = {
  [FILL]: 'accent-overlay',
  [FIT]: 'accent-overlay',
  [INSET]: 'category-solid',
  [BLUR]: 'accent-overlay',
  [LEGACY_BLUR]: 'accent-overlay',
  [WIDE_INSET]: 'category-solid'
};

const defaultHeaderTreatment = FILL;
const headerTreatments = imageToHeaderTreatments(imageTreatments);
const headerTreatmentsConfig = {
  imageTreatments,
  defaultHeaderTreatment,
  headerTreatments,
  heroContentItems: ['tagline', 'tagline2', 'cta'],
  nonHeroContentItems: ['phone'],
  mediaSupport: {
    [NONE]: [defaultHeaderTreatment],
    [IMAGE]: Object.keys(imageTreatments),
    [VIDEO]: [defaultHeaderTreatment],
    [SLIDESHOW]: [defaultHeaderTreatment]
  }
};
const defaultPaintJob = CUSTOM;

export default {
  id,
  name: 'kai',
  packs: {
    color: '#75978D',
    font: 'karla'
  },
  logo: {
    font: 'primary'
  },
  packCategories: {
    color: colorPackCategories.ACCENT
  },
  headerProperties: {
    alignmentOption: 'left',
    defaultLogoAlign: 'center'
  },
  headerTreatmentsConfig,
  showSlideshowTab: true,
  hasNavBackground: false,
  paintJobs: [LIGHT, LIGHT_ALT, LIGHT_COLORFUL, COLORFUL, DARK_COLORFUL, DARK_ALT, DARK],
  defaultPaintJob,
  buttons: {
    primary: {
      fill: buttons.fills.GHOST,
      shape: buttons.shapes.PILL,
      decoration: buttons.decorations.NONE,
      shadow: buttons.shadows.NONE,
      color: buttons.colors.HIGHCONTRAST
    },
    secondary: {
      fill: buttons.fills.GHOST,
      decoration: buttons.decorations.NONE,
      shadow: buttons.shadows.NONE,
      color: buttons.colors.HIGHCONTRAST
    },
    ...COMMON_BUTTON_CONFIG
  }
};
